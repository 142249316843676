import React from "react";
import "./Experience.css";

function Experience() {
  return (
    <div className="experiencesPage" id="experiencePage">
      <div className="experienceContainer">
        <div className="experienceContainerTitle">
          <h1>My Skills</h1>
        </div>
        <div className="experiences">
          <div className="experience">
            <div className="experienceHeader">
              <div className="experienceLogo" id="experiencePictureOne"></div>
              <h4 className="experienceTitle">
                Front
                <br />
                End
              </h4>
            </div>
            <div className="experienceDescription">
              Skilled in developing both functional and OOP: Python, JavaScrip, 
              GitHub, Netifly, VS Code.
            </div>
          </div>

          <div className="experience">
            <div className="experienceHeader">
              <div className="experienceLogo" id="experiencePictureTwo"></div>
              <h4 className="experienceTitle">
                Back
                <br />
                End
              </h4>
            </div>
            <div className="experienceDescription">
              {/* Passionate about UI/UX. Development experience in HTML, CSS, JS,
              React. */}

              Adept at building server-side applications using Node.js and Express.js.
            </div>
          </div>

          <div className="experience">
            <div className="experienceHeader">
              <div className="experienceLogo" id="experiencePictureThree"></div>
              <h4 className="experienceTitle">
                Data
                <br />
                Base
              </h4>
            </div>
            <div className="experienceDescription">
              {/* Experienced in NodeJS, ExpressJS, MongoDB, Mongoose, Restful APIs. */}
              Experienced with MongoDB, Mongoose, and integrating databases with RESTful APIs.            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
