import React from "react";
import "./NavBar.css";

function NavBar() {
  return (
    <nav className="navBar">
      <a href="#personalPageStopper">
        <div className="brand">Cesar Iparrea</div>
      </a>
      <div className="options">
        <a href="#parallaxPage">
          <button className="navBarItem">Home</button>
        </a>
        <div>//</div>
        <a href="#experiencePage">
          <button className="navBarItem">Skills</button>
        </a>
        <div>//</div>
        <a href="#projectsPage">
          <button className="navBarItem">Projects</button>
        </a>
        <div>//</div>
        <a href="#footer">
          <div className="navBarItem">Contact</div>
        </a>
      </div>
      {/* <div className="menu">Menu</div> */}
    </nav>
  );
}

export default NavBar;
