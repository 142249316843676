import "./App.css";
import NavBar from "./components/navBar/NavBar.jsx";
import Parallax from "./components/parallax/Parallax.jsx";
import Bio from "./components/bio/Bio.jsx";
import Experience from "./components/experience/Experience.jsx";
import Footer from "./components/footer/Footer.jsx";
import Sticky from "./components/sticky/Sticky.js";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Parallax />
      <div className="topLines" id="personalPageStopper">
        <div className="longLine"></div>
        <div className="shortLine"></div>
      </div>
      <Bio />
      <div className="bottomLines" id="bottomLinesSpacer">
        <div className="shortLine"></div>
        <div className="longLine"></div>
      </div>
      <Experience />
      <div className="topLines" id="projectDivider">
        <div className="longLine"></div>
        <div
          className="shortLine"
          data-aos="fade-up"
          data-aos-duration="3000"
        ></div>
      </div>
      <Sticky />
      <Footer />
    </div>
  );
}

export default App;
