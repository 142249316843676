import React from "react";
import "./Bio.css";

function Bio() {
  return (
    <div className="personal" id="personalPage">
      <div className="personalPicture"></div>
      <div className="personalInfo">
        <div className="personalTitle">
          <h1 className="titleLineOne">Who</h1>
          <h1 className="titleLineTwo">am I?</h1>
        </div>
        <div className="personalDescription">
          I am a Software Engineer and a competitive athlete who enjoys breaking
          down complex problems and presenting simple user-friendly solutions. I
          take pride in knowing how to work with people of different skill sets,
          personalities, and backgrounds to accomplish common goals.
        </div>
      </div>
    </div>
  );
}

export default Bio;
