import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="footerContent">
        <h2>Available for select freelance opportunities</h2>
        <h3>Have an exciting project you need help with?</h3>
        <h4>Send me an email or contact me on LinkedIn</h4>
      </div>
      <div className="footerLinks">
        <a href="mailto:iparreacesar@gmail.com">
          <h1>iparreacesar@gmail.com</h1>
        </a>
        <div className="socialMedia">
          <a href="https://github.com/CIparrea" target="_blank">
            <div className="gitHub"></div>
          </a>
          <a href="https://www.linkedin.com/in/cesariparrea/" target="_blank">
            <div className="linkedIn"></div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
