import React, { useEffect, useState } from "react";
import "./Parallax.css";
import flatIron from "../../img/flatIron.png";
import topClouds from "../../img/topClouds.png";
import newYorker from "../../img/newYorker.png";
import leftBuildings from "../../img/leftBuildings.png";
import frontBuilding from "../../img/frontBuilding.png";
import bottomClouds from "../../img/bottomClouds.png";

function Parallax() {
  const [parallax, setParallax] = useState([]);

  useEffect(() => {
    setParallax(document.querySelectorAll(".parallax"));
  }, []);

  let xValue = 0;
  let yValue = 0;
  let rotateDegree = 0;

  window.addEventListener("mousemove", (e) => {
    console.log("moving");
    xValue = e.clientX - window.innerWidth / 2;
    yValue = e.clientY - window.innerHeight / 2;
    rotateDegree = (xValue / (window.innerWidth / 2)) * 5;

    parallax.forEach((el) => {
      let speedx = el.dataset.speedx;
      let speedy = el.dataset.speedy;
      let speedz = el.dataset.speedz;

      let isInleft =
        parseFloat(getComputedStyle(el).left) < window.innerWidth / 2 ? 1 : -1;
      let zValue =
        (e.clientX - parseFloat(getComputedStyle(el).left)) * isInleft * 0.3;

      el.style.transform = `translateX(calc(-50% + ${
        -xValue * speedx
      }px)) translateY(calc(-50% + ${
        -yValue * speedy
      }px)) perspective(2300px) translateZ(${
        zValue * speedz
      }px) rotateY(${rotateDegree}deg)`;
    });

    e.stopPropagation();
  });

  return (
    <div className="landingPage" id="parallaxPage">
      <img
        src={flatIron}
        data-speedx="0.09"
        data-speedy="0.09"
        data-speedz="0"
        data-distance="600"
        className="parallax flatIron-img"
      />
      <img
        src={topClouds}
        data-speedx="0.12"
        data-speedy="0.12"
        data-speedz="0.4"
        data-distance="-200"
        className="parallax topClouds-img"
      />
      <img
        src={newYorker}
        data-speedx="0.07"
        data-speedy="0.07"
        data-speedz="0.5"
        data-distance="100"
        className="parallax newYorker-img"
      />
      <div
        className="parallax text"
        data-speedx="0.075"
        data-speedy="0.075"
        data-speedz="0.6"
        id="firstName"
      >
        <h1>CESAR</h1>
        <h2>IPARREA</h2>
      </div>
      <div
        className="parallax text"
        data-speedx="0.075"
        data-speedy="0.075"
        data-speedz="0.6"
        id="lastName"
      >
        <h1>CESAR</h1>
        <h2>IPARREA</h2>
      </div>
      <img
        src={bottomClouds}
        data-speedx="0.15"
        data-speedy="0.15"
        data-speedz="0.7"
        data-distance="90"
        className="parallax bottomClouds-img"
      />
      <img
        src={leftBuildings}
        data-speedx="0.09"
        data-speedy="0.09"
        data-speedz="0.8"
        data-distance="900"
        className="parallax leftBuildings-img"
      />
      <img
        src={frontBuilding}
        data-speedx="0.1"
        data-speedy="0.1"
        data-speedz="0.9"
        data-distance="900"
        className="parallax frontBuilding-img"
      />
    </div>
  );
}

export default Parallax;
